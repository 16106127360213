import React, { useEffect, useState, useContext } from "react";
import {
  Drawer,
  Button,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  RadioGroup,
  Snackbar,
  Badge,
  TextField,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import GlobalContext from "../Context/globalContext";
import { Instance, imageUrl } from "../Config";
import { useHistory, useParams } from "react-router";
import {
  SHOW_SNACKBAR,
  DELETE_CART,
  INIT_CART,
} from "../Context/globalActions.types";
import { decode } from "jsonwebtoken";
import { Link } from "react-router-dom";

import ListIcon from "@material-ui/icons/List";
import useSWR from "swr";

export default function CartDrawer({ isOpen, toggleDrawer }) {
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [accCd, setAccCd] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [error, setError] = useState(null);
  const [isFetch, setIsFetch] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);

  const history = useHistory();
  const params = useParams();
  let mobileNo = params.mobileNo;

  const { globalContext, dispatch } = useContext(GlobalContext);

  // const [cartItems, setCartItems] = useState([]);

  // const handleAddItem = (id) => {
  //   setUpdateTotal(!updateTotal);
  //   dispatch({
  //     type: ADD_CART_QUANTITY,
  //     payload: id,
  //   });
  // };

  // const handleRemoveItem = (id) => {
  //   setUpdateTotal(!updateTotal);
  //   dispatch({
  //     type: REMOVE_CART_QUANTITY,
  //     payload: id,
  //   });
  // };

  const { data: resAccountBySman, error: resAccountBySmanError } = useSWR(
    `/account/getAccountBySman?mobileNo=${params.mobileNo}&smanCd=${
      decode(globalContext.user).cd
    }`
  );

  const [isPaymentModeValid, setIsPaymentModeValid] = React.useState(false);
  const [paymentMode, setPaymentMode] = useState("Cash on Delivery");

  const handleClick = () => {
    setIsPaymentModeValid(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsPaymentModeValid(false);
  };

  //TODO: Optimized the calculation of the total

  const calculaeTotal = () => {
    setIsFetch(true);

    if (
      globalContext.settings.filter((e) => e.VARIABLE == "prol")[0].VALUE == "Y"
    ) {
      setSubTotal(
        globalContext.cart.reduce(
          (accumulator, current) =>
            Number(accumulator) +
            Number(current.RATE) *
              Number(
                current.QUANTITY *
                  current.item.variant.find((e) => e.SIZE_CD == current.SIZE_CD)
                    .DESC1
              ),
          0
        )
      );
      setTax(
        globalContext.cart.reduce(
          (accumulator, current) =>
            Number(accumulator) +
            (Number(current.NET_AMOUNT) -
              Number(current.RATE) *
                Number(
                  current.QUANTITY *
                    current.item.variant.find(
                      (e) => e.SIZE_CD == current.SIZE_CD
                    ).DESC1
                )),
          0
        )
      );
      setTotal(
        globalContext.cart.reduce(
          (accumulator, current) =>
            Number(accumulator) + Number(current.NET_AMOUNT),
          0
        )
      );
    } else {
      setSubTotal(
        globalContext.cart.reduce(
          (accumulator, current) =>
            Number(accumulator) +
            Number(current.RATE) * Number(current.QUANTITY),
          0
        )
      );
      setTax(
        globalContext.cart.reduce(
          (accumulator, current) =>
            Number(accumulator) +
            (Number(current.NET_AMOUNT) -
              Number(current.RATE) * Number(current.QUANTITY)),
          0
        )
      );
      setTotal(
        globalContext.cart.reduce(
          (accumulator, current) =>
            Number(accumulator) + Number(current.NET_AMOUNT),
          0
        )
      );
    }
  };

  const removeItem = (id) => {
    Instance.delete(`/cart/${id}`)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: DELETE_CART,
            payload: id,
          });
          setUpdateTotal(!updateTotal);
        }
      })
      .catch((e) => {
        setError("Something went wrong");
        setTimeout(() => {
          setError(null);
        }, 5000);
      });
  };

  const fetchData = () => {
    Instance.get(
      `/cart?mobileNo=${mobileNo}&partyCd=${decode(globalContext.user).cd}`
    )
      .then((res) => {
        if (res.status == 200) {
          setIsFetch(false);
          dispatch({
            payload: res.data.result,
            type: INIT_CART,
          });
          setUpdateTotal(!updateTotal);
        }
      })
      .catch((e) => {
        setIsFetch(false);
        setError("Something went wrong");
        setTimeout(() => {
          setError(null);
        }, 5000);
      });
  };

  const handleCheckout = () => {
    if (globalContext.user && decode(globalContext.user).groupCd == "88") {
      history.push(`/${mobileNo}/checkout`);
    } else {
      if (paymentMode == "") {
        setErrorMessage("Please Select Payment Mode");
        handleClick();
        return;
      }
      if (decode(globalContext.user).cd.length == 4) {
        if (accCd == "") {
          setErrorMessage("Please Select Order account");
          handleClick();
          return;
        }
      }
      const noOfItem = globalContext.cart.reduce(
        (accumulator, current) =>
          Number(accumulator) + Number(current.QUANTITY),
        0
      );
      Instance.post("/orders", {
        partyId:
          decode(globalContext.user).cd.length == 4
            ? accCd
            : decode(globalContext.user).cd,
        itemAmt: subTotal,
        noOfItem: noOfItem,
        netAmt: total,
        mobileNo: mobileNo,
        sman:
          decode(globalContext.user).cd.length == 4
            ? decode(globalContext.user).cd
            : null,
        paymentMode: paymentMode,
        orderItm: globalContext.cart,
      })
        .then((res) => {
          if (res.status == 200) {
            for (let i = 0; i < globalContext.cart.length; i++) {
              Instance.delete(`/cart/${globalContext.cart[i].cartId}`).then(
                (cres) => {
                  dispatch({
                    type: DELETE_CART,
                    payload: globalContext.cart[i].cartId,
                  });
                  if (
                    cres.status == 200 &&
                    i == globalContext.cart.length - 1
                  ) {
                    history.push(
                      `/${mobileNo}/cart/complete?orderId=${res.data.result.orderId}`
                    );
                  }
                }
              );
            }
          }
        })
        .catch((e) => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: "Order unable to be placed",
              type: "error",
            },
          });
        });
    }
  };

  useEffect(() => {
    if (decode(globalContext.user)) {
      fetchData();
    }
  }, [globalContext.user]);

  useEffect(() => {
    if (isFetch) {
      fetchData();
    }
    calculaeTotal();
  }, [updateTotal, isOpen]);

  return (
    <React.Fragment>
      <li onClick={toggleDrawer(true)}>
        <Link
          to="#"
          style={{
            color: globalContext.owner[0]
              ? globalContext.owner[0].COLOR?.primaryColorText
              : "",
          }}
        >
          <Badge badgeContent={globalContext.cart.length} color="secondary">
            {globalContext.settings.filter(
              (e) => e.VARIABLE == "customOrderLabel"
            )[0].VALUE == "Y" ? (
              <ListIcon className="text-xl cursor-pointer" />
            ) : (
              <ShoppingCartOutlinedIcon className="text-xl cursor-pointer" />
            )}
          </Badge>
        </Link>
      </li>
      <Snackbar
        open={isPaymentModeValid}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        style={{
          zIndex: "9999",
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        <div className="rightMenu-scroll w-80 md:w-96">
          <div
            className="d-flex align-items-center justify-content-between py-3 px-3"
            style={{
              backgroundColor: globalContext.owner[0]
                ? globalContext.owner[0].COLOR?.primaryColor
                : "",
              color: globalContext.owner[0]
                ? globalContext.owner[0].COLOR?.primaryColorText
                : "",
            }}
          >
            <h4
              className="fs-md ft-medium mb-0"
              style={{
                color: globalContext.owner[0]
                  ? globalContext.owner[0].COLOR?.primaryColorText
                  : "",
              }}
            >
              Products List
            </h4>
            <button onClick={toggleDrawer(false)} className="close_slide">
              <i className="ti-close"></i>
            </button>
          </div>
          <div className="right-ch-sideBar h-full">
            <div
              className="cart_select_items py-2 absolute overflow-auto bottom-[26%] left-0 right-0 top-14 hide-scroll-bar"
              style={{
                zIndex: -999,
              }}
            >
              {globalContext.settings.filter(
                (e) => e.VARIABLE == "customOrderLabel"
              )[0].VALUE == "N" && globalContext.cart.length == 0 ? (
                <div className="flex items-center flex-col">
                  <ShoppingCartOutlinedIcon className="text-9xl mt-12" />
                  <p className="text-2xl mt-5">Your Cart is Empty</p>
                  <div className="mt-3 text-center w-[21rem]">
                    Please add some items in your Cart to proceed to checkout.
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    className="mt-2"
                    href={`/${mobileNo}/shop`}
                  >
                    Continue Shopping
                  </Button>
                </div>
              ) : (
                globalContext.cart.map((item) => (
                  <div
                    className="d-flex align-items-center justify-content-between br-bottom px-3 py-3"
                    key={item.cartId}
                  >
                    <div className="cart_single d-flex align-items-center">
                      <div className="cart_selected_single_thumb">
                        {item.img ? (
                          <img
                            src={imageUrl + "/" + item.img.IMGURL[0]}
                            alt={item.ITEM_NAME}
                            width="60"
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src="/img/11.jpg"
                            width="60"
                            className="img-fluid"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="cart_single_caption pl-2">
                        <h4 className="product_title fs-sm ft-medium mb-0 lh-1 capitalize">
                          {item.ITEM_NAME.toString().toLowerCase()}
                        </h4>
                        <p className="mb-2">
                          <span className="ft-medium small">
                            {item.SIZE_CD}, Qty:
                            <span className="ml-1">{item.QUANTITY}</span>
                          </span>
                          {globalContext.settings.length != 0 ? (
                            globalContext.settings.filter(
                              (e) => e.VARIABLE == "prol"
                            )[0].VALUE == "Y" ? (
                              <span className="small mt-1 ml-2">
                                ({item.QUANTITY} X{" "}
                                {
                                  item.item.variant.find(
                                    (e) => e.SIZE_CD == item.SIZE_CD
                                  ).DESC1
                                }{" "}
                                ={" "}
                                {item.QUANTITY *
                                  item.item.variant.find(
                                    (e) => e.SIZE_CD == item.SIZE_CD
                                  ).DESC1}
                                )
                              </span>
                            ) : null
                          ) : null}
                        </p>
                        <h4 className="fs-md ft-medium mb-0 lh-1">
                          {" "}
                          ₹{item.RATE}
                          {globalContext.settings.length != 0 ? (
                            globalContext.settings.filter(
                              (e) => e.VARIABLE == "enableUom"
                            )[0].VALUE == "Y" ? (
                              <span className="fs-sm">/{item.item.UOM}</span>
                            ) : null
                          ) : null}
                        </h4>
                      </div>
                    </div>
                    <div className="fls_last">
                      <button
                        className="close_slide gray"
                        onClick={() => removeItem(item.cartId)}
                      >
                        <i className="ti-close"></i>
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="absolute bottom-0 bg-white w-80 md:w-96">
              {globalContext.settings.filter(
                (e) => e.VARIABLE == "customOrderLabel"
              )[0].VALUE == "Y" ? (
                <div className="cart_action px-3 py-3">
                  <div className="form-group">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disableElevation
                      className="w-full"
                      onClick={handleCheckout}
                    >
                      {globalContext.settings.filter(
                        (e) => e.VARIABLE == "customOrderLabel"
                      )[0].VALUE == "Y"
                        ? "Inquiry Now"
                        : "Checkout Now"}
                    </Button>
                  </div>
                </div>
              ) : globalContext.cart ? (
                globalContext.cart.length != 0 ? (
                  <React.Fragment>
                    {globalContext.user ? (
                      decode(localStorage.getItem("user")).groupCd != "88" ? (
                        <div className="px-3 py-1">
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">
                              Payment Mode
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              onChange={(e) => {
                                setPaymentMode(e.target.value);
                              }}
                              value={paymentMode == "" ? null : paymentMode}
                            >
                              <FormControlLabel
                                value="Cash on Delivery"
                                control={
                                  <Radio
                                    style={{
                                      color: "#303F9F",
                                    }}
                                    size="small"
                                    className="pl-0"
                                  />
                                }
                                label="Cash on Delivery"
                                className="m-0"
                              />
                              {/* <FormControlLabel
                              value="Cash against order confirm"
                              control={
                                <Radio
                                  style={{
                                    color: "#303F9F",
                                  }}
                                  size="small"
                                />
                              }
                              label="Cash against order confirm"
                            /> */}
                              <FormControlLabel
                                value="As per Company Terms"
                                control={
                                  <Radio
                                    style={{
                                      color: "#303F9F",
                                    }}
                                    size="small"
                                    className="pl-0"
                                  />
                                }
                                label="As per Company Terms"
                                className="m-0"
                              />
                              <FormControlLabel
                                value="Bank"
                                control={
                                  <Radio
                                    style={{
                                      color: "#303F9F",
                                    }}
                                    size="small"
                                    className="pl-0"
                                  />
                                }
                                label="Bank"
                                className="m-0"
                              />
                            </RadioGroup>
                          </FormControl>
                          {decode(localStorage.getItem("user")).cd.length ==
                          4 ? (
                            <Autocomplete
                              id="combo-box-demo"
                              options={
                                resAccountBySman
                                  ? resAccountBySman.data.result
                                  : []
                              }
                              getOptionLabel={(option) => option.ACC_NAME}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Account"
                                  variant="outlined"
                                />
                              )}
                              onChange={(e, newValue) => {
                                setAccCd(newValue.ACC_CD);
                              }}
                            />
                          ) : null}
                        </div>
                      ) : null
                    ) : null}
                    <div className="d-flex align-items-center justify-content-between br-top br-bottom px-3 py-1.5">
                      <h6 className="mb-0">Subtotal</h6>
                      <h3 className="mb-0 text-lg ft-medium">₹{subTotal}</h3>
                    </div>
                    <div className="d-flex align-items-center justify-content-between br-top br-bottom px-3 py-1.5">
                      <h6 className="mb-0">Tax</h6>
                      <h3 className="mb-0 text-lg ft-medium">₹{tax}</h3>
                    </div>
                    <div className="d-flex align-items-center justify-content-between br-top br-bottom px-3 py-1.5">
                      <h6 className="mb-0">Total</h6>
                      <h3 className="mb-0 text-lg ft-medium">₹{total}</h3>
                    </div>

                    <div className="cart_action px-3 pt-2">
                      <div className="form-group">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disableElevation
                          className="w-full"
                          onClick={handleCheckout}
                        >
                          Checkout Now
                        </Button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}
