import React, { useEffect, useState, useContext } from "react";
import Footer from "../../../Components/Footer";
import GlobalContext from "../../../Context/globalContext";
import { Redirect, useParams, Link } from "react-router-dom";
import { decode } from "jsonwebtoken";
import {
  SIGN_IN,
  SHOW_SNACKBAR,
  LOAD_ORDER,
} from "../../../Context/globalActions.types";

import { Button, CircularProgress, TextField } from "@material-ui/core";
import BottomBar from "../Components/BottomBar";
import CustomSnakBar from "../../../Components/CustomSnakBar";
import { imageUrl, Instance } from "../../../Config";
import ProfileDrawer from "../Components/ProfileDrawer";

const Orders = () => {
  const params = useParams();
  let mobileNo = params.mobileNo;

  const { globalContext, dispatch } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [pMobileNo, setpMobileNo] = useState("");
  const [isB2C, setIsB2C] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    if (decode(localStorage.getItem("user")).cd.length == 4) {
      Instance.get(
        `/orders/sman?mobileNo=${mobileNo}&partyCd=${
          decode(localStorage.getItem("user")).cd
        }&per_page=${10}&page=${page}`
      )
        .then((res) => {
          if (res.data.result.length == 0) {
            setIsLoading(false);
          }
          setPage(res.data.pagination.nextPage);
          setIsLoading(false);
          dispatch({
            type: LOAD_ORDER,
            payload: res.data.result,
          });
          const products = data.concat(res.data.result);
          setData(products);
        })
        .catch(() => {
          setIsLoading(false);
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: "Something went wrong",
              type: "error",
            },
          });
        });
    } else {
      Instance.get(
        `/orders?mobileNo=${mobileNo}&partyCd=${
          decode(localStorage.getItem("user")).cd
        }&per_page=${10}&page=${page}`
      )
        .then((res) => {
          if (res.data.result.length == 0) {
            setIsLoading(false);
          }
          setPage(res.data.pagination.nextPage);
          setIsLoading(false);
          dispatch({
            type: LOAD_ORDER,
            payload: res.data.result,
          });
          const products = data.concat(res.data.result);
          setData(products);
        })
        .catch(() => {
          setIsLoading(false);
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: "Something went wrong",
              type: "error",
            },
          });
        });
    }
  };

  const fetchDataByGroupCd = () => {
    setIsLoading(true);
    Instance.get(
      `/orders?mobileNo=${mobileNo}&partyCd=${
        decode(localStorage.getItem("user")).cd
      }&per_page=${10}&page=${page}&groupCd=${
        decode(localStorage.getItem("user")).groupCd
      }&pMobileNo=${pMobileNo}`
    )
      .then((res) => {
        if (res.data.result.length == 0) {
          setIsLoading(false);
        }
        setPage(res.data.pagination.nextPage);
        setIsLoading(false);
        dispatch({
          type: LOAD_ORDER,
          payload: res.data.result,
        });
        const products = data.concat(res.data.result);
        setData(products);
      })
      .catch(() => {
        setIsLoading(false);
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      });
  };

  useEffect(() => {
    if (localStorage.getItem("user")) {
      if (decode(localStorage.getItem("user")).groupCd == "88") {
        setIsB2C(true);
      } else {
        fetchData();
      }
    }
  }, []);

  return (
    <div>
      <ProfileDrawer
        breadcrubms={
          <div className="gray py-3">
            <div className="container">
              <div className="row">
                <div className="colxl-12 col-lg-12 col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={`/${params.mobileNo}/`}>Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {globalContext.settings.length != 0
                          ? globalContext.settings.filter(
                              (e) => e.VARIABLE == "customOrderLabel"
                            )[0].VALUE == "Y"
                            ? "My Inquiry"
                            : "My Order"
                          : ""}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        }
      >
        {decode(localStorage.getItem("user")).groupCd == "88" &&
        isB2C == true ? (
          <React.Fragment>
            <TextField
              variant="outlined"
              placeholder="Mobile No"
              value={pMobileNo}
              size="small"
              onChange={(e) => setpMobileNo(e.target.value)}
            />
            <Button
              variant="contained"
              disableElevation
              className="ml-5 btn bg-dark"
              color="primary"
              onClick={() => {
                setIsB2C(false);
                fetchDataByGroupCd();
              }}
            >
              Search
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!isLoading && data.length == 0 ? (
              <div className="mt-5 mx-auto">No Order Found</div>
            ) : (
              data.map((e) => (
                <div
                  className="ord_list_wrap border mb-4 mfliud"
                  key={e.orderId}
                >
                  <div className="ord_list_head gray d-flex align-items-center justify-content-between px-3 py-3">
                    <div className="olh_flex">
                      <p className="m-0 p-0">
                        <span className="text-muted">
                          {globalContext.settings.length != 0
                            ? globalContext.settings.filter(
                                (e) => e.VARIABLE == "customOrderLabel"
                              )[0].VALUE == "Y"
                              ? "Inquiry Number"
                              : "Order Number"
                            : ""}
                        </span>
                      </p>
                      <h6 className="mb-0 ft-medium">
                        #{e.ORDER_NO ? e.ORDER_NO : ""}
                      </h6>
                    </div>
                    <div className="olh_flex">
                      <p className="m-0 p-0">
                        <span className="text-muted">
                          {globalContext.settings.length != 0
                            ? globalContext.settings.filter(
                                (e) => e.VARIABLE == "customOrderLabel"
                              )[0].VALUE == "Y"
                              ? "Inquiry Date:"
                              : "Order Date:"
                            : ""}
                        </span>
                      </p>
                      <h6 className="mb-0 ft-medium fs-sm">
                        {new Date(e.createdAt).toLocaleString(undefined, {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </h6>
                    </div>
                  </div>
                  <div className="ord_list_body text-left">
                    {e.orderItm.map((itm) => (
                      <div
                        className="row align-items-center justify-content-center m-0 py-4 br-bottom"
                        key={itm.oitmid}
                      >
                        <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                          <div className="cart_single d-flex align-items-start mfliud-bot">
                            <div className="cart_selected_single_thumb">
                              {itm.img ? (
                                <img
                                  src={imageUrl + "/" + itm.img.IMGURL[0]}
                                  className="img-fluid rounded"
                                  alt=""
                                  width="75"
                                />
                              ) : (
                                <img
                                  src="/img/11.jpg"
                                  width="75"
                                  className="img-fluid rounded"
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="cart_single_caption pl-3">
                              <p className="mb-0">
                                <span className="text-muted small capitalize">
                                  {globalContext.category.find(
                                    (e) => e.DEPT_CD == itm.DEPT_CD
                                  )
                                    ? globalContext.category.find(
                                        (e) => e.DEPT_CD == itm.DEPT_CD
                                      ).dept_name
                                      ? globalContext.category
                                          .find((e) => e.DEPT_CD == itm.DEPT_CD)
                                          .dept_name.toString()
                                          .toLowerCase()
                                      : ""
                                    : null}
                                </span>
                              </p>
                              <h4 className="product_title fs-sm ft-medium mb-1 lh-1 capitalize">
                                {itm.ITEM_NAME.toString().toLowerCase()}
                              </h4>
                              <p className="mb-2">
                                <span className="text-dark medium">
                                  Size: {itm.SIZE_CD}
                                </span>
                                ,{" "}
                                <span className="text-dark medium">
                                  Qty: {itm.QUANTITY}{" "}
                                  {globalContext.settings.length != 0 ? (
                                    globalContext.settings.filter(
                                      (e) => e.VARIABLE == "prol"
                                    )[0].VALUE == "Y" ? (
                                      <span className="small mt-1 ml-2">
                                        ({itm.QUANTITY} X{" "}
                                        {
                                          itm.item.variant.find(
                                            (e) => e.SIZE_CD == itm.SIZE_CD
                                          ).DESC1
                                        }{" "}
                                        ={" "}
                                        {itm.QUANTITY *
                                          itm.item.variant.find(
                                            (e) => e.SIZE_CD == itm.SIZE_CD
                                          ).DESC1}
                                        )
                                      </span>
                                    ) : null
                                  ) : null}
                                </span>
                              </p>
                              <h4 className="fs-sm ft-bold mb-0 lh-1">
                                ₹{itm.NET_AMOUNT}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                          <p className="mb-1 p-0">
                            <span className="text-muted">Status</span>
                          </p>
                          <div className="delv_status">
                            <span
                              className={
                                itm.ISTATUS == "Pending"
                                  ? "ft-medium small text-warning bg-light-warning rounded px-3 py-1"
                                  : itm.ISTATUS == "Dispatch"
                                  ? "ft-medium small text-warning bg-light-warning rounded px-3 py-1"
                                  : itm.ISTATUS == "Completed"
                                  ? "ft-medium small text-success bg-light-success rounded px-3 py-1"
                                  : itm.ISTATUS == "Canceled"
                                  ? "ft-medium small text-danger bg-light-danger rounded px-3 py-1"
                                  : ""
                              }
                            >
                              {itm.ISTATUS}
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                          <p className="mb-1 p-0">
                            <span className="text-muted">
                              Quantity Delivered:
                            </span>
                          </p>
                          <h6 className="mb-0 ft-medium fs-sm">
                            {itm.DLVR_QUANTITY}
                          </h6>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="ord_list_footer d-flex align-items-center justify-content-between br-top px-3 w-full">
                    <div className="pr-0 py-2 olf_flex d-flex align-items-center justify-content-between">
                      {/* <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disableElevation
                        className="mb-2 w-full"
                      >
                        Download Performa
                      </Button> */}
                    </div>
                    <div className="pr-0 py-2 olf_flex d-flex align-items-center justify-content-between">
                      <div className="olf_inner_right">
                        <h5 className="mb-0 fs-sm ft-bold">
                          Total: ₹{e.NET_AMT}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
            {isLoading ? (
              <div className=" mt-10 flex justify-center">
                <CircularProgress />
              </div>
            ) : page ? (
              <div className="my-10 flex justify-center">
                <Button
                  variant="outlined"
                  onClick={
                    decode(localStorage.getItem("user")).groupCd == "88"
                      ? fetchDataByGroupCd
                      : fetchData
                  }
                >
                  Load More
                </Button>
              </div>
            ) : null}
          </React.Fragment>
        )}
      </ProfileDrawer>
    </div>
  );
};

export default Orders;
