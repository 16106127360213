import React, { useEffect, useContext, useState } from "react";
import {
  useLocation,
  useParams,
  Link,
  useHistory,
  Redirect,
} from "react-router-dom";

import GlobalContext from "../../Context/globalContext";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { imageUrl } from "../../Config";
import BottomBar from "./Components/BottomBar";
import { Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const OrderSuccess = () => {
  const { globalContext } = useContext(GlobalContext);

  const history = useHistory();

  const params = useParams();
  const location = useLocation();
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  return (
    <div>
      <Header>
        <div className="pt-[1.25rem]">
          <div className="gray py-3">
            <div className="container">
              <div className="row">
                <div className="colxl-12 col-lg-12 col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={`/${params.mobileNo}`}>Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Support</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {globalContext.settings.length != 0
                          ? globalContext.settings.filter(
                              (e) => e.VARIABLE == "customOrderLabel"
                            )[0].VALUE == "Y"
                            ? "Inquiry Completed"
                            : "Complete Order"
                          : ""}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="middle">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                <div className="p-4 d-inline-flex align-items-center justify-content-center circle bg-light-success text-success mx-auto mb-4">
                  {globalContext.settings.length != 0 ? (
                    globalContext.settings.filter(
                      (e) => e.VARIABLE == "customOrderLabel"
                    )[0].VALUE == "Y" ? (
                      <CheckIcon />
                    ) : (
                      <i className="lni lni-heart-filled fs-lg"></i>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <h2 className="mb-2 ft-bold">
                  {globalContext.settings.length != 0
                    ? globalContext.settings.filter(
                        (e) => e.VARIABLE == "customOrderLabel"
                      )[0].VALUE == "Y"
                      ? "Your Order Inquiry has been sent!"
                      : "Your Order is Completed!"
                    : ""}
                </h2>
                {globalContext.settings.length != 0 ? (
                  globalContext.settings.filter(
                    (e) => e.VARIABLE == "customOrderLabel"
                  )[0].VALUE == "Y" ? (
                    <p className="ft-regular fs-md mb-5">
                      Our Contact Person will respond you shorty.
                    </p>
                  ) : (
                    <p className="ft-regular fs-md mb-5">
                      Your order{" "}
                      <span className="text-body text-dark">
                        #{getQueryVariable("orderId")}
                      </span>{" "}
                      has been completed. To pay for the order, Please refer
                      below.
                    </p>
                  )
                ) : (
                  ""
                )}

                {globalContext.owner && globalContext.owner.length != 0 ? (
                  globalContext.owner[0].PAYMENTIMG ? (
                    <React.Fragment>
                      <div className="d-inline-flex align-items-center justify-content-center mx-auto mb-4">
                        <img
                          src={
                            imageUrl + "/" + globalContext.owner[0].PAYMENTIMG
                          }
                          height={200}
                          width={200}
                          alt="payment Image"
                        />
                      </div>
                      <p className="ft-regular fs-md mb-5">
                        NOTE: Please Share the reference number/ScreenShot of
                        the payment to the owner to verify the payment.
                      </p>
                    </React.Fragment>
                  ) : null
                ) : null}
                {globalContext.settings.length != 0 ? (
                  globalContext.settings.filter(
                    (e) => e.VARIABLE == "customOrderLabel"
                  )[0].VALUE == "Y" ? null : (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => {
                        history.push(`/${params.mobileNo}/orders`);
                      }}
                    >
                      Track Your Orders
                    </Button>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>

        <Footer />
        <BottomBar />
      </Header>
    </div>
  );
};

export default OrderSuccess;
